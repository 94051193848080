// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
//
import SvgColor from '../svg-color';

// ----------------------------------------------------------------------

export default function SplashScreen({ sx, ...other }: BoxProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        right: 0,
        width: 1,
        bottom: 0,
        height: 1,
        zIndex: 9998,
        display: 'flex',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: alpha(theme.palette.background.default, 0.7),
        ...sx,
      }}
      {...other}
    >
      <SvgColor src="/assets/splash.svg" color="primary.main" sx={{ width: 60, height: 50 }} />
    </Box>
  );
}
